@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scrollbar-gutter: stable;
}

@layer utilities {
  .animate-loading {
    @apply animate-[loadingSpinner_2s_linear_infinite];
  }

  .min-h-dyn {
    min-height: 100vh;
    min-height: 100dvh;
  }

  .fade-in {
    @apply animate-[fadeIn_1s_ease-out_forwards];
  }

  .fade-green {
    @apply animate-[fadeGreen_0.3s_ease-out_forwards];
  }
}

@keyframes fadeGreen {
  from {
    @apply text-inherit;
  }
  to {
    @apply text-green-500;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg);
    stroke-dashoffset: 99;
  }
  to {
    transform: rotate(360deg);
    stroke-dashoffset: -99;
  }
}
